/** @jsx jsx */
import React from 'react'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import {jsx, Styled} from 'theme-ui'
import * as dayjs from 'dayjs'
import {Layout, SEO} from '../components'
import {Container} from '../elements'
import Navigation from '../components/navigation'

require('dayjs/locale/de')

dayjs.locale('de') // use locale

const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

const EventPage = ({
  data: {
    events,
    EventSite,
    EventSite: {lang},
    i18nPathname,
  },
  location,
}) => (
  <Layout>
    <Navigation lang={lang} i18nPathname={i18nPathname.nodes} />
    <SEO data={EventSite.data} pathname={location.pathname} />
    <div
      sx={{
        mb: [(t) => t.layout.separation.mobile, '', '8rem'],
      }}
    >
      <div
        sx={{
          backgroundColor: 'primary',
          p: ['7rem 1.5rem 5rem 1.5rem', '', '12rem 1.5rem 8rem 1.5rem'],
        }}
      >
        <div
          sx={{
            maxWidth: (t) => t.layout.big,
            m: '0 auto',
          }}
        >
          <h1
            sx={{
              fontSize: ['1rem', '', '1.5rem'],
              lineHeight: 1.4,
              color: '#fde3ce',
              m: ['0 0 0.4rem 0', '', '0'],
            }}
          >
            {EventSite.data.header_title}
          </h1>
          <h2
            sx={{
              fontSize: ['2rem', '', '2.5rem'],
              lineHeight: 1.25,
              color: (t) => t.colors.gray[1],
              m: 0,
            }}
          >
            {EventSite.data.header_subtitle}
          </h2>
          <hr
            sx={{
              backgroundColor: '#fff',
              height: '4px',
              width: ['calc(100vw - 6rem)', '', '12rem'],
              margin: '0.25rem 0 0 0',
              padding: '0',
              border: 0,
            }}
          />
        </div>
      </div>
    </div>

    <Container type="big">
      {events.edges.map((item) => (
        <React.Fragment>
          {item.node.isFuture && (
            <React.Fragment>
              <Styled.h1
                sx={{
                  mb: '1rem',
                  fontSize: [4, 4, '2.5rem'],
                }}
              >
                Datum:{' '}
                <Styled.h1
                  sx={{
                    fontSize: [4, 4, '2.5rem'],
                    mb: 0,
                    color: (t) => t.colors.green[5],
                    display: 'inline-block',
                  }}
                >
                  {item.node.data.event_date}
                </Styled.h1>
              </Styled.h1>
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  marginBottom: '4rem',
                }}
              >
                {/* Image */}

                <div
                  sx={{
                    position: 'relative',
                    mb: ['3rem', '', '', '0'],
                    minHeight: '300px',
                    transition: (t) => t.transitions.boom.transition,
                    borderRadius: (t) => t.borderRadius.default,
                    boxShadow: '0 14px 30px rgba(0, 0, 0, 0.18)',
                    img: {
                      borderRadius: (t) => t.borderRadius.default,
                    },
                    '&:hover': {
                      boxShadow: '0 18px 48px rgba(0, 0, 0, 0.18)',
                      transform: 'translateY(-4px)',
                    },
                    a: {
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      borderRadius: `${(t) => t.borderRadius.default}`,
                      '& > div': {
                        position: 'static !important',
                        pb: '0 !important',
                      },
                      '& > div > div': {
                        position: 'static !important',
                        pb: '0 !important',
                      },
                      '&:focus': {
                        outline: 'none',
                        boxShadow: `0 0 0 5px ${(t) => t.colors.primary}`,
                      },
                    },
                    flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
                    maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
                    width: 'calc(99.9% * 2 / 5 - 1rem)',
                    '@media (max-width: 800px)': {
                      flexBasis: '100%',
                      maxWidth: '100%',
                      width: '100%',
                    },
                  }}
                >
                  <Link to={`/veranstaltungen/${item.node.uid}`}>
                    <Img
                      fluid={
                        item.node.data.image.localFile.childImageSharp.fluid
                      }
                    />
                  </Link>
                </div>
                {/* Content */}
                <div
                  sx={{
                    flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
                    maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
                    width: 'calc(99.9% * 3 / 5 - 1rem)',
                    '@media (max-width: 800px)': {
                      flexBasis: '100%',
                      maxWidth: '100%',
                      width: '100%',
                    },
                  }}
                >
                  <Link to={`/veranstaltungen/${item.node.uid}`}>
                    <h1
                      sx={{
                        fontSize: ['1.5rem', '', '2.25rem'],
                        color: (t) => t.colors.gray[8],
                        lineHeight: 1.3,
                        mt: 0,
                        mb: '0.25rem',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          color: '#e36d0d',
                        },
                      }}
                    >
                      {item.node.data.cb_title}
                    </h1>
                  </Link>
                  <span
                    sx={{
                      color: (t) => t.colors.gray[6],
                      fontSize: '1.25rem',
                    }}
                  >
                    {dayjs().to(
                      dayjs(item.node.data.event_date).format('DD/MM/YYYY')
                    )}
                  </span>
                  <Styled.p
                    sx={{
                      mt: '1rem',
                      color: (t) => t.colors.gray[8],
                    }}
                  >
                    {item.node.data.cb_desc}
                  </Styled.p>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      ))}

      {/* Old events */}
      {events.edges.map(
        (item) =>
          !item.node.isFuture && (
            <React.Fragment>
              <Styled.h1
                sx={{
                  fontSize: [4, 4, '2.5rem'],
                  mb: '1rem',
                  color: (t) => t.colors.red[6],
                }}
              >
                Vorbei
              </Styled.h1>
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  marginBottom: '4rem',
                }}
              >
                {/* Image */}
                <div
                  sx={{
                    position: 'relative',
                    mb: ['3rem', '', '', '0'],
                    minHeight: '300px',
                    transition: (t) => t.transitions.boom.transition,
                    borderRadius: (t) => t.borderRadius.default,
                    boxShadow: '0 14px 30px rgba(0, 0, 0, 0.18)',
                    img: {
                      borderRadius: (t) => t.borderRadius.default,
                    },
                    '&:hover': {
                      boxShadow: '0 18px 48px rgba(0, 0, 0, 0.18)',
                      transform: 'translateY(-4px)',
                    },
                    a: {
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      borderRadius: `${(t) => t.borderRadius.default}`,
                      '& > div': {
                        position: 'static !important',
                        pb: '0 !important',
                      },
                      '& > div > div': {
                        position: 'static !important',
                        pb: '0 !important',
                      },
                      '&:focus': {
                        outline: 'none',
                        boxShadow: `0 0 0 5px ${(t) => t.colors.primary}`,
                      },
                    },
                    flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
                    maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
                    width: 'calc(99.9% * 2 / 5 - 1rem)',
                    '@media (max-width: 800px)': {
                      flexBasis: '100%',
                      maxWidth: '100%',
                      width: '100%',
                    },
                  }}
                >
                  <Link to={`/veranstaltungen/${item.node.uid}`}>
                    <Img
                      fluid={
                        item.node.data.image.localFile.childImageSharp.fluid
                      }
                    />
                  </Link>
                </div>
                {/* Content */}
                <div
                  sx={{
                    flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
                    maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
                    width: 'calc(99.9% * 3 / 5 - 1rem)',
                    '@media (max-width: 800px)': {
                      flexBasis: '100%',
                      maxWidth: '100%',
                      width: '100%',
                    },
                  }}
                >
                  <Link to={`/veranstaltungen/${item.node.uid}`}>
                    <h1
                      sx={{
                        fontSize: ['1.5rem', '', '2.25rem'],
                        color: (t) => t.colors.gray[8],
                        lineHeight: 1.3,
                        mt: 0,
                        mb: '0.25rem',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          color: '#e36d0d',
                        },
                      }}
                    >
                      {item.node.data.cb_title}
                    </h1>
                  </Link>
                  <span
                    sx={{
                      color: (t) => t.colors.gray[6],
                      fontSize: '1.25rem',
                    }}
                  >
                    {dayjs().to(
                      dayjs(item.node.data.event_date).format('DD/MM/YYYY')
                    )}
                  </span>
                  <Styled.p
                    sx={{
                      mt: '1rem',
                      color: (t) => t.colors.gray[8],
                    }}
                  >
                    {item.node.data.cb_desc}
                  </Styled.p>
                </div>
              </div>
            </React.Fragment>
          )
      )}
    </Container>
  </Layout>
)
export const query = graphql`
  query EventSite($lang: String) {
    i18nPathname: allPrismicEventsSite {
      nodes {
        uid
        lang
      }
    }

    EventSite: prismicEventsSite(lang: {eq: $lang}) {
      uid
      lang
      data {
        header_title
        header_subtitle

        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }

    events: allPrismicEvents(
      filter: {lang: {eq: $lang}}
      sort: {order: ASC, fields: data___event_date}
    ) {
      edges {
        node {
          uid
          isFuture
          data {
            cb_title
            cb_desc
            event_date(formatString: "DD/MM/YYYY", locale: "de")
            image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            body1 {
              ... on PrismicEventsBody1Text {
                slice_type
                id
                primary {
                  text {
                    html
                  }
                }
              }
              ... on PrismicEventsBody1Image {
                slice_type
                id
                primary {
                  image {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicEventsBody1Quote {
                id
                primary {
                  quote {
                    text
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

export default EventPage
